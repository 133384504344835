




































































































































































































































































































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { invitesPayload, OtpVerifyAndsSignUp } from '@/Interfaces/StoreInterFaces/accountInterfaces/SignupInterface'
import { required, email, minLength, maxLength, numeric } from 'vuelidate/lib/validators'
import { Action, Getter } from 'vuex-class'
import AuthService from '@/services/auth'
import darkLogo from '@/assets/adori-logo-dark.png'
import whiteLogo from '@/assets/adori-without-text.svg'
import mixpanel from 'mixpanel-browser'

// utils
import { getQueryParam } from '@/utils/misc'

const validations = {
  signup: {
    signupName: {
      required,
      minLength: minLength(4),
    },
    signupLastName: {},
    signupEmail: {
      required,
      email,
    },
    signupPassword: {
      required,
      minLength: minLength(6),
    },
  },
  signin: {
    signinEmail: {
      required,
      email,
    },
    signinPassword: {
      required,
    },
  },
  forgotpassword: {
    email: {
      required,
      email,
    },
  },
  otp: {
    required,
    numeric,
    maxLength: maxLength(6),
  },
}

@Component({
  // @ts-ignore
  validations,
})
export default class ViewLogin extends Mixins(validationMixin) {
  @Getter networkId!: string
  @Getter signinStatus!: any
  @Getter hasLoadedUser!: boolean
  @Getter hasYoutubeUploads!: boolean

  @Action requestSignUpOtp!: any
  @Action youtubeSignUp!: any
  @Action youtubeLogin!: any
  @Action getIntegrationsSummary!: any
  @Action pushNotification!: any
  @Action loadUser!: any

  showPass = false
  mode = 'SIGNUP'
  haveOtp = false
  $v!: any

  signup = {
    signupName: '',
    signupLastName: '',
    signupEmail: '',
    signupPassword: '',
  }
  signin = {
    signinEmail: '',
    signinPassword: '',
  }

  forgotpassword = {
    email: '',
  }

  otp = ''

  signupLoader = false
  signinLoader = false
  passwordLoader = false
  confirmLoader = false
  incorrectOtp = false

  error = ''

  defaultVideoNetworkType = 'VIDEO_FREE'
  defaultStudioNetworkType = 'VIDEO_FREE'

  get showId() {
    return this.$route.query?.showId
  }

  get youtubeSignInStatus() {
    return this.signinStatus[this.showId ? this.showId : this.networkId].youtube || false
  }

  get signInPermission() {
    return this.showId ? !this.$permissions.isShowMangeAllowed(this.showId) : !this.$permissions.isYoutubeLoginAllowed()
  }

  created() {
    if (window.location.origin.includes('studio')) {
      localStorage.clear()
      const url = `${window.location.origin.replace('adorilabs', 'adoriai')}`
      window.location.href = `${url.replace('studio', 'video')}/login`
    }
  }

  async mounted() {
    this.$watch(
      () => this.$route,
      () => {
        window.scrollTo(0, 0)
        this.$route.name === 'login' && (this.mode = 'LOGIN')
        this.$route.name === 'signup' && (this.mode = 'SIGNUP')
        this.$route.name === 'forgotPass' && (this.mode = 'FORGOTPASSWORD')
        this.$route.name === 'otp' && (this.mode = 'OTP')
      }
    )
    const networkType = getQueryParam('network')
    const product = getQueryParam('product')
    if (!!networkType) {
      sessionStorage.setItem('networkType', networkType)
      this.$router.replace(this.$route.path)
    }
    if (!!product) {
      localStorage.setItem('selectedProduct', product)
      this.$router.replace(this.$route.path)
    }
    window.scrollTo(0, 0)
    this.$route.name === 'login' && (this.mode = 'LOGIN')
    this.$route.name === 'signup' && (this.mode = 'SIGNUP')
    this.$route.name === 'forgotPass' && (this.mode = 'FORGOTPASSWORD')
    this.$route.name === 'otp' && (this.mode = 'OTP')

    if (this.$route.name === 'youtubeTerms') {
      this.mode = 'LOADER'
      !this.$route.query?.youtube && (await this.loadUser())
      await this.getIntegrationsSummary({
        networkId: this.networkId ? this.networkId : localStorage.getItem('networkId'),
        showId: this.showId,
      })
      if (this.youtubeSignInStatus) window.close()
      //   Enable this when verification from youtube needed
      this.handleYoutubeLogin()
      //   this.mode = 'YOUTUBETERMS'
    }
  }

  handleMode(mode: string) {
    this.mode = mode
    mode === 'SIGNUP' && this.$router.push({ name: 'signup' })
    mode === 'LOGIN' && this.$router.push({ name: 'login' })
    mode === 'FORGOTPASSWORD' && this.$router.push({ name: 'forgotPass' })
  }

  get darkLogo() {
    return darkLogo
  }

  get whiteLogo() {
    return whiteLogo
  }

  get signupSubmit() {
    return (
      !this.$v.signup.signupName.$invalid &&
      !this.$v.signup.signupEmail.$invalid &&
      !this.$v.signup.signupPassword.$invalid &&
      (this.haveOtp ? !this.$v.otp.$invalid : true)
    )
  }
  get signinSubmit() {
    return !this.$v.signin.signinEmail.$invalid && !this.$v.signin.signinPassword.$invalid
  }
  get forgotPasswordSubmit() {
    return !this.$v.forgotpassword.email.$invalid
  }
  get optCheck() {
    return !(this.$v.otp.$model.length < 6) && !this.$v.otp.$invalid
  }

  get isStudio() {
    return window.location.origin.includes('studio') && this.$route.name !== 'youtubeTerms'
  }

  handleSignUpEnter() {
    if (this.signupSubmit && !this.signupLoader) {
      this.haveOtp ? this.handleConfirm() : this.handleSignUp()
    }
  }

  async handleSignUp() {
    const signUpType = sessionStorage.getItem('networkType')
    let networkType: any = this.isStudio ? this.defaultStudioNetworkType : this.defaultVideoNetworkType
    if (signUpType) {
      networkType = signUpType
    }
    const payload: invitesPayload = {
      email: this.signup.signupEmail,
      grantType: 'OTP',
      networkType: networkType,
      resend: false,
    }
    try {
      this.signupLoader = true
      const res = await this.requestSignUpOtp(payload)
      this.signupLoader = false
      this.mode = 'OTP'
      this.$router.push({ name: 'otp' })
      this.otp = ''
    } catch (e) {
      this.signupLoader = false
    }
  }

  async handleGoogleSignIn() {
    localStorage.setItem('AUTH', 'GOOGLE')
    await AuthService.googleAuthorisation(this.handleResponse)
  }

  async resendOtp() {
    const signUpType = sessionStorage.getItem('networkType')
    let networkType: any = this.isStudio ? this.defaultStudioNetworkType : this.defaultVideoNetworkType
    if (signUpType) {
      networkType = signUpType
    }
    const payload: invitesPayload = {
      email: this.signup.signupEmail,
      grantType: 'OTP',
      networkType: networkType,
      resend: true,
    }
    try {
      this.signupLoader = true
      await this.requestSignUpOtp(payload)
      this.signupLoader = false
    } catch (e) {
      this.signupLoader = false
    }
  }

  handleSignInEnter() {
    if (this.signinSubmit && !this.signinLoader) this.handleSignIn()
  }

  async handleSignIn() {
    this.signinLoader = true
    await AuthService.login(this.signin.signinEmail, this.signin.signinPassword, this.handleResponse)
    this.$gtag.event('sign-in', { action: 'Sign in' })
  }

  async handleYoutubeLogin() {
    // @ts-ignore
    if (this.signInPermission) {
      this.pushNotification({
        text: `Sorry! You don't have permission to perform this action`,
        type: 'WARNING',
      })
      return
    }
    this.mode = 'LOADER'
    const payload: any = {
      networkId: this.networkId,
      next: `${window.origin}/youtubeAuth`,
    }
    this.showId && (payload['showId'] = this.showId)
    const resp = await this.youtubeLogin(payload)
    mixpanel.track('Youtube Login')
    this.$gtag.event('youtube-login', { action: 'Youtube Login' })
    window.location.href = resp.url
  }

  handleResponse(err: any, result: any) {
    if (err) {
      this.error = err.description
      setTimeout(() => {
        this.error = ''
      }, 5000)
      this.signinLoader = false
    } else {
      this.signinLoader = false
    }
  }

  handleOtpEnter() {
    if (this.optCheck && !this.confirmLoader) {
      this.handleConfirm()
    }
  }

  async handleConfirm() {
    const payload: OtpVerifyAndsSignUp = {
      email: this.signup.signupEmail,
      password: this.signup.signupPassword,
      confirmPassword: this.signup.signupPassword,
      name: this.signup.signupName,
      lastName: this.signup.signupLastName,
      otp: this.otp,
    }
    const affiliate = localStorage.getItem('affiliate')
    affiliate &&
      ['listen_notes', 'iheart', 'blubrry', 'blubrry'].includes(affiliate) &&
      (payload['affiliate'] = affiliate)

    try {
      this.signupLoader = true
      this.confirmLoader = true
      await this.youtubeSignUp(payload)
      localStorage.setItem('isNewUser', 'true')
      mixpanel.track('Sign Up')
      this.$gtag.event('sign-up', { action: 'Sign up' })
      await AuthService.login(this.signup.signupEmail, this.signup.signupPassword, this.handleResponse)
      affiliate && localStorage.removeItem('affiliate')
    } catch (e) {
      this.incorrectOtp = true
      setTimeout(() => {
        this.incorrectOtp = false
      }, 3000)
      this.confirmLoader = false
      this.signupLoader = false
    }
  }

  handleForgotPasswordEnter() {
    this.forgotPasswordSubmit && this.changePassword()
  }

  async changePassword() {
    try {
      this.passwordLoader = true
      await AuthService.changePassword(this.forgotpassword.email, () => {
        this.passwordLoader = false
        this.mode = 'LOGIN'
      })
      this.$gtag.event('change-password', { action: 'Change Password' })

      this.$store.dispatch('pushNotification', {
        text: 'Change password link sent to your registered email address',
        type: 'SUCCESS',
      })
    } catch (e) {
      this.passwordLoader = false
    }
  }

  openTerms() {
    window.open('https://adoriai.com/terms-and-conditions')
  }

  openPrivacy() {
    window.open('https://adoriai.com/privacy-policy')
  }
}
